import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback, useContext, useState } from "react";
import Spacer from "../utilities/Spacer";
import Theme from "../utilities/Theme/client";
import { FormContext } from "../utilities/fields/FieldsForm";
import Icon, { IconName, IconProps } from "./Icon";
import Intersperse from "./Intersperse";
import Clickable, { ClickableProps } from "./Clickable";
import Spinner from "./Spinner";
import Text from "./text/Text";

export type ButtonStyle = "normal" | "danger" | "discreet";
export type ButtonDisplay =
  | "normal"
  | "normalDisabled"
  | "danger"
  | "dangerDisabled"
  | "discreet"
  | "discreetDisabled";

const colorPerDisplay: Record<ButtonDisplay, string> = {
  normal: Theme.color,
  normalDisabled: rgba("black", 0.5),
  danger: Theme.error,
  dangerDisabled: rgba("black", 0.5),
  discreet: Theme.color,
  discreetDisabled: rgba("black", 0.5),
};

const backgroundPerDisplay: Record<ButtonDisplay, string> = {
  normal: rgba(Theme.color, 0.1),
  normalDisabled: rgba("black", 0.1),
  danger: rgba(Theme.error, 0.1),
  dangerDisabled: rgba("black", 0.1),
  discreet: "transparent",
  discreetDisabled: "transparent",
};

const borderColorPerDisplay: Record<ButtonDisplay, string> = {
  normal: rgba(Theme.color, 1),
  normalDisabled: rgba("black", 0.5),
  danger: rgba(Theme.error, 1),
  dangerDisabled: rgba("black", 0.5),
  discreet: "transparent",
  discreetDisabled: "transparent",
};

const hoverBackgroundPerDisplay: Record<ButtonDisplay, string> = {
  ...backgroundPerDisplay,
  normal: rgba(Theme.color, 0.8),
  danger: rgba(Theme.error, 0.8),
  discreet: rgba(Theme.color, 0.2),
};

const hoverColorPerDisplay: Record<ButtonDisplay, string> = {
  ...colorPerDisplay,
  normal: "white",
  danger: "white",
  discreet: Theme.color,
};

export type ButtonProps = ClickableProps & {
  icon?: IconName | IconProps;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: ButtonStyle;
};

export default function Button(props: ButtonProps) {
  const { icon, label, disabled, style = "normal", onClick } = props;

  const display: ButtonDisplay = `${style}${disabled ? "Disabled" : ""}`;

  const [loadingForced, setLoadingForced] = useState<boolean>(false);
  const form = useContext(FormContext);

  const onClickWithLoading = useCallback(
    async (e: React.MouseEvent) => {
      if (!onClick) return;
      try {
        setLoadingForced(true);
        await onClick(e);
      } finally {
        setLoadingForced(false);
      }
    },
    [onClick],
  );

  const loading =
    props.loading ||
    (form
      ? form.loading && "submit" in props && props.submit === true
      : false) ||
    loadingForced;

  const buttonCss = css({
    background: backgroundPerDisplay[display],
    borderWidth: 1,
    borderColor: borderColorPerDisplay[display],
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 12,
    paddingRight: 12,
    color: colorPerDisplay[display],
    borderStyle: "solid",
    borderRadius: 6,
    transition: "all 200ms",
    cursor: disabled ? "not-allowed" : "pointer",
    "&:hover": {
      background: hoverBackgroundPerDisplay[display],
      color: hoverColorPerDisplay[display],
    },
    pointerEvents: loading ? "none" : undefined,
    position: "relative",
    display: "inline-block",
    textDecoration: "none",
  });

  const contentCss = css({
    display: "flex",
    alignItems: "center",
    opacity: loading ? 0 : 1,
    transition: "all 100ms",
    flexDirection: "row",
  });

  const spinnerContainerCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: loading ? 1 : 0,
    transition: "all 200ms",
  });

  const content = (
    <Fragment>
      <span css={contentCss}>
        <Intersperse between={() => <Spacer scale={0.5} horizontal />}>
          {icon ? (
            <Text tag="span">
              {typeof icon === "string" ? (
                <Icon name={icon} />
              ) : (
                <Icon {...icon} />
              )}
            </Text>
          ) : null}
          {label ? <Text tag="span">{label}</Text> : null}
        </Intersperse>
      </span>
      <span css={spinnerContainerCss}>
        <Text tag="span">
          <Spinner />
        </Text>
      </span>
    </Fragment>
  );

  return (
    <Clickable
      {...props}
      onClick={props.onClick ? onClickWithLoading : undefined}
      css={buttonCss}
    >
      {content}
    </Clickable>
  );
}
