import React, { Fragment, ReactNode } from "react";
import dayjs from "dayjs";
import Print, { Printed } from "@hpo/client/utilities/print/Print";
import {
  OverallStatistics,
  ProjectsPerformance,
  TagsPerformance,
} from "@hpo/client/models/Yearly";
import Units, { UnitName } from "@hpo/client/utilities/Units";
import { Performance } from "@hpo/client/models/Performance";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import Programs from "@hpo/client/utilities/Programs";
import { IndicatorTypeLabels } from "@hpo/client/utilities/enums/IndicatorType";

export type YearlyReportProps = {
  title: string;
  periods: Array<string>;
  overall: OverallStatistics | null;
  tags: TagsPerformance | null;
  projects: ProjectsPerformance | null;
};

export default function YearlyReport(props: YearlyReportProps) {
  const { title, periods, overall, tags, projects } = props;
  return (
    <Printed>
      <Print.WithLogo logo="/logo-cg.png">
        <h1>{title}</h1>
        <p>{`En date du ${dayjs().format("LL")}`}</p>
      </Print.WithLogo>
      <OverallStatisticsTable overall={overall} periods={periods} />
      <Print.PageBreak />
      <TagsTable tags={tags} periods={periods} />
      <Print.PageBreak />
      <ProjectsTable projects={projects} periods={periods} />
    </Printed>
  );
}

type OverallStatisticsTableProps = {
  overall: OverallStatistics | null;
  periods: Array<string>;
};

function OverallStatisticsTable(props: OverallStatisticsTableProps) {
  const { periods, overall } = props;
  if (overall === null) return null;

  return (
    <Fragment>
      <h2>Statistiques générales</h2>
      {periods.length === 1
        ? `Statistiques générales du programme HPO concernent la période ${periods[0]}`
        : `Statistiques générales du programme HPO concernent les périodes ${smoothJoin(periods)}`}
      <table>
        <thead>
          <tr>
            <th>Par appel à projet</th>
            <th>Structures financées</th>
            <th>Projets financées</th>
            <th>Montants conventionnés</th>
            <th>Montants versés</th>
            <th>Taux de consommation</th>
          </tr>
        </thead>
        <tbody>
          {overall.byProgram.map((program) => {
            const { statistics } = program;
            return (
              <tr key={program.key}>
                <td>{Programs[program.key].label}</td>
                <td>{statistics.targetedOrganizations}</td>
                <td>{statistics.targetedProjects}</td>
                <td>{Units.euro.display(statistics.grantedAmount)}</td>
                <td>{Units.euro.display(statistics.certifiedAmount)}</td>
                <td>
                  {Units.percentage.display(statistics.amountsRatio, "-")}
                  <ProgressBar ratio={statistics.amountsRatio} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Tous appels à projets confondus</th>
            <th>{overall.allPrograms.targetedOrganizations}</th>
            <th>{overall.allPrograms.targetedProjects}</th>
            <th>{Units.euro.display(overall.allPrograms.grantedAmount)}</th>
            <th>{Units.euro.display(overall.allPrograms.certifiedAmount)}</th>
            <th>
              {Units.percentage.display(overall.allPrograms.amountsRatio, "-")}
              <ProgressBar ratio={overall.allPrograms.amountsRatio} />
            </th>
          </tr>
        </tfoot>
      </table>
      <Print.Space size={0.5} />
      <table>
        <thead>
          <tr>
            <th>Par bénéficiaire</th>
            <th>Projets financées</th>
            <th>Montants conventionnés</th>
            <th>Montants versés</th>
            <th>Taux de consommation</th>
          </tr>
        </thead>
        <tbody>
          {overall.byOrganization.map((org) => {
            const { statistics } = org;
            return (
              <tr key={org.id}>
                <td>{org.label}</td>
                <td>{statistics.targetedProjects}</td>
                <td>{Units.euro.display(statistics.grantedAmount)}</td>
                <td>{Units.euro.display(statistics.certifiedAmount)}</td>
                <td>
                  {Units.percentage.display(statistics.amountsRatio, "-")}
                  <ProgressBar ratio={statistics.amountsRatio} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>
              {`Tous bénéficiaires (${overall.allOrganizations.targetedOrganizations}) confondus`}
            </th>
            <th>{overall.allOrganizations.targetedProjects}</th>
            <th>
              {Units.euro.display(overall.allOrganizations.grantedAmount)}
            </th>
            <th>
              {Units.euro.display(overall.allOrganizations.certifiedAmount)}
            </th>
            <th>
              {Units.percentage.display(
                overall.allOrganizations.amountsRatio,
                "-",
              )}
              <ProgressBar ratio={overall.allOrganizations.amountsRatio} />
            </th>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}

type TagsTableProps = {
  periods: Array<string>;
  tags: TagsPerformance | null;
};

function TagsTable(props: TagsTableProps) {
  const { periods, tags } = props;
  if (tags === null) return null;
  return (
    <Fragment>
      <h2>Indicateurs d'impact</h2>
      {`Indicateurs d'impacts pour la période ${smoothJoin(periods)} et synthèse pour chaque appel à projet`}
      {tags.map((tag) => {
        return (
          <Fragment key={tag.id}>
            <h3>{tag.label}</h3>
            <table>
              <thead>
                <tr>
                  <th>Par structure et par projet</th>
                  {periods.map((p) => (
                    <th key={p}>{p}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tag.byIndicator.map((indicator) => {
                  const { id, label, project, organization, performances } =
                    indicator;
                  return (
                    <tr key={id}>
                      <td>
                        {label}
                        <br />
                        <small>
                          {organization.label} / {project.label}
                        </small>
                      </td>
                      {periods.map((p) => (
                        <td key={p}>
                          {displayPerformance(
                            getPerformanceAndPrevious(performances, p, periods),
                            tag.unit,
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>{tag.label}</th>
                  {periods.map((p) => (
                    <th key={p}>
                      {displayPerformance(
                        getPerformanceAndPrevious(
                          tag.allIndicators,
                          p,
                          periods,
                        ),
                        tag.unit,
                      )}
                    </th>
                  ))}
                </tr>
              </tfoot>
            </table>
            <Print.Space size={0.5} />
            <table>
              <thead>
                <tr>
                  <th>Par appel à projet</th>
                  {periods.map((p) => (
                    <th key={p}>{p}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tag.byProgram.map((program) => {
                  const { id, label, performances } = program;
                  return (
                    <tr key={id}>
                      <td>{label}</td>
                      {periods.map((p) => (
                        <td key={p}>
                          {displayPerformance(
                            getPerformanceAndPrevious(performances, p, periods),
                            tag.unit,
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>{tag.label}</th>
                  {periods.map((p) => (
                    <th key={p}>
                      {displayPerformance(
                        getPerformanceAndPrevious(tag.allPrograms, p, periods),
                        tag.unit,
                      )}
                    </th>
                  ))}
                </tr>
              </tfoot>
            </table>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

type ProjectsTableProps = {
  periods: Array<string>;
  projects: ProjectsPerformance | null;
};

function ProjectsTable(props: ProjectsTableProps) {
  const { periods, projects } = props;
  if (projects === null) return null;
  return (
    <Fragment>
      <h2>
        Indicateurs d’évaluation figurant dans les conventions de financement
      </h2>
      {`Indicateurs d'évalution pour ${periods.length === 1 ? periods[0] : smoothJoin(periods)}, pour chaque projet (par appel à projet et bénéficiaire)`}
      {projects.map((program) => {
        return (
          <Fragment key={program.key}>
            <h3>{Programs[program.key].label}</h3>
            {program.organizations.map((o) => {
              return (
                <Fragment key={o.label}>
                  <h4>{o.label}</h4>
                  {o.projects.map((project) => {
                    return (
                      <table>
                        <thead>
                          <tr>
                            <th>{project.label}</th>
                            {periods.map((p) => (
                              <th key={p}>{p}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {project.indicators.map((indicator) => {
                            const { id, label, unit, type, performances } =
                              indicator;
                            return (
                              <tr key={id}>
                                <td>
                                  {label}
                                  <br />
                                  <small>{IndicatorTypeLabels[type]}</small>
                                </td>
                                {periods.map((p) => {
                                  return (
                                    <td key={p}>
                                      {displayPerformance(
                                        getPerformanceAndPrevious(
                                          performances,
                                          p,
                                          periods,
                                        ),
                                        unit,
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    );
                  })}
                </Fragment>
              );
            })}
          </Fragment>
        );
        // return (
        //   <Fragment key={project.id}>
        //     <h3>{project.label}</h3>
        //     <p>Par {project.organization}</p>

        //   </Fragment>
        // );
      })}
    </Fragment>
  );
}

type PerformanceAndPrevious = {
  current: Performance | null;
  previous: Performance | null;
};

function getPerformanceAndPrevious(
  perfs: Record<string, Performance>,
  period: string,
  periods: Array<string>,
) {
  const current = period in perfs ? perfs[period] : null;
  const currentPeriodIndex = periods.indexOf(period);
  const prevPeriod =
    currentPeriodIndex > 0 ? periods[currentPeriodIndex - 1] : null;
  const previous =
    prevPeriod === null ? null : prevPeriod in perfs ? perfs[prevPeriod] : null;
  return { current, previous };
}

function displayPerformance(
  perfs: PerformanceAndPrevious,
  unit: UnitName,
): ReactNode {
  const { current, previous } = perfs;
  let output: ReactNode = null;
  if (current) {
    const { actual, goal, ratio } = current;
    if (actual === null) {
      if (goal === null) {
        output = "-";
      } else {
        output = `Objectif ${Units[unit].display(goal)}`;
      }
    } else {
      if (goal === null) {
        output = Units[unit].display(actual);
      } else {
        output = (
          <Fragment>
            {Units[unit].display(actual)}
            <small>/{Units[unit].display(goal)}</small>
          </Fragment>
        );
      }
      output = <div>{output}</div>;
    }
    if (ratio !== null) {
      output = (
        <Fragment>
          {output}
          <ProgressBar ratio={ratio} />
        </Fragment>
      );
    }
    if (
      current !== null &&
      current.actual !== null &&
      current.actual !== 0 &&
      previous !== null &&
      previous.actual !== null
    ) {
      const progress = current.actual / previous.actual - 1;
      output = (
        <Fragment>
          {output}
          <small
            className={[
              "variation",
              switchVariation(progress, "positive", "neutral", "negative"),
            ].join(" ")}
          >
            {switchVariation(progress, "▲", "-", "▼")}
            {Units.percentage.display(progress)}
          </small>
        </Fragment>
      );
    }
  }
  return <div className="performance">{output}</div>;
}

function ProgressBar(props: { ratio: number | null }) {
  const { ratio } = props;
  if (ratio === null) return null;
  return (
    <div className="progressbar">
      <div
        className={
          ratio >= 1
            ? "progressbar-thumb-full"
            : ratio >= 0.5
              ? "progressbar-thumb-half"
              : "progressbar-thumb-few"
        }
        style={{
          width: Math.round(ratio * 100) + "%",
        }}
      ></div>
    </div>
  );
}

function switchVariation<T>(
  variation: number,
  ifPositive: T,
  ifNeutral: T,
  ifNegative: T,
): T {
  if (variation === 0) return ifNeutral;
  else if (variation > 0) return ifPositive;
  else return ifNegative;
}
