import React, { Fragment, ReactNode } from "react";
import dayjs, { extend, locale } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fr from "dayjs/locale/fr";
import { findIndex, maxBy, sumBy } from "lodash";
import { getPaymentLabel } from "@hpo/client/models/BeneficiaryPayment";
import { ConventionDetails } from "@hpo/client/models/Convention";
import { InstructorPaymentDetails } from "@hpo/client/models/InstructorPayment";
import Units from "@hpo/client/utilities/Units";
import Receipts from "@hpo/client/utilities/Receipts";
import Amounts from "@hpo/client/utilities/Amounts";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { PaymentFragmentLabels } from "@hpo/client/utilities/enums/PaymentFragment";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import Print from "@hpo/client/utilities/print/Print";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";
import Programs from "@hpo/client/utilities/Programs";

extend(localizedFormat);
locale(fr);

type PaymentReportProps = {
  convention: ConventionDetails;
  payment: InstructorPaymentDetails;
  signatures: boolean;
};

export default function PaymentReport(props: PaymentReportProps) {
  const { convention, payment, signatures } = props;

  const conventionInitialAmount = sumBy(convention.fundings, (f) => f.amount);

  const periodPayments = convention.payments.filter(
    (p) => p.period === payment.period,
  );

  const previousPayments = periodPayments.slice(
    0,
    findIndex(periodPayments, (p) => p.id === payment.id),
  );

  const header = (
    <Print.WithLogo logo={"/logo-cg.png"}>
      <p>Rapport d'instruction sur la demande de paiement</p>
      <h1>
        {getPaymentLabel(payment)}
        <br />
        {smoothJoin(convention.organizations.map((o) => o.businessName))}
      </h1>
    </Print.WithLogo>
  );

  const orgsNode = (
    <section className="hashed">
      <h2>
        {convention.organizations.length === 1
          ? "Bénéficiaire"
          : "Bénéficiaires"}
      </h2>
      <Print.Columns>
        {convention.organizations.map((org) => {
          return (
            <Print.Properties
              key={org.id}
              props={[
                ["Raison sociale", org.businessName],
                ["SIRET", org.legalId],
                ["Statut juridique", org.type],
                [
                  "Réprésentant légal",
                  `${org.legalRepresentativeName} (${org.legalRepresentativePosition})`,
                ],
                [
                  "Adresse",
                  <Fragment>
                    {org.address.street}
                    <br />
                    {org.address.postalCode} - {org.address.town}
                  </Fragment>,
                ],
              ]}
            />
          );
        })}
      </Print.Columns>
    </section>
  );

  const conventionNode = (
    <section className="hashed">
      <h2>Convention</h2>
      <Print.Properties
        props={[
          ["Numéro", convention.reference],
          ["Date", dayjs(convention.date, "YYYY-MM-DD").format("LL")],
          [
            convention.amendments ? "Montant annuel initial" : "Montant annuel",
            Units.euro.display(conventionInitialAmount),
          ],
        ]}
      />
    </section>
  );

  const projectsNode = (
    <section className="hashed">
      <h2>{convention.fundings.length === 1 ? "Projet" : "Projets"}</h2>
      <table>
        <thead>
          <tr>
            <th>Nom du projet</th>
            <th>Appel à projet</th>
          </tr>
        </thead>
        <tbody>
          {convention.fundings.map((f) => (
            <tr key={f.id}>
              <td>{f.project.label}</td>
              <td>{Programs[f.project.type].label}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );

  let historyTable: ReactNode;

  const advance =
    maxBy([...previousPayments, payment].map((p) => p.maximalAdvance || 0)) ||
    0;

  const periodCertifiedAmount = sumBy(
    previousPayments,
    (p) => p.certifiedAmount || 0,
  );
  const periodGrantedAmount = sumBy(periodPayments, (p) => p.grantedAmount);

  if (advance > 0) {
    const totalPaid =
      advance + sumBy(previousPayments, (p) => p.transferAmount || 0);

    historyTable = (
      <table>
        <thead>
          <tr>
            <th>Phase</th>
            <th>Montant accordé</th>
            <th>Montant versé</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Montant versé avant la convention</td>
            <td></td>
            <td>{Units.euro.display(advance)}</td>
          </tr>
          {previousPayments.map((p) => {
            if (p.certifiedAmount !== null) {
              return (
                <tr key={p.id}>
                  <td>{getPaymentLabel(p)}</td>
                  <td>{Units.euro.display(p.certifiedAmount)}</td>
                  <td>{Units.euro.display(p.transferAmount)}</td>
                </tr>
              );
            }
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>
              {`${Units.euro.display(periodCertifiedAmount)}`}
              {periodGrantedAmount > 0 ? (
                <Fragment>
                  <br />
                  <small>{`sur ${Units.euro.display(periodGrantedAmount)} soit ${Units.percentage.display(periodCertifiedAmount / periodGrantedAmount)}`}</small>
                </Fragment>
              ) : null}
            </th>
            <th>
              {`${Units.euro.display(totalPaid)}`}
              {totalPaid > periodCertifiedAmount && periodCertifiedAmount ? (
                <Fragment>
                  <br />
                  <small>
                    dont {Units.euro.display(totalPaid - periodCertifiedAmount)}{" "}
                    d'en-cours
                  </small>
                </Fragment>
              ) : null}
            </th>
          </tr>
        </tfoot>
      </table>
    );
  } else if (previousPayments.length > 0) {
    historyTable = (
      <table>
        <thead>
          <tr>
            <th>Phase</th>
            <th>Montant accordé et versé</th>
          </tr>
        </thead>
        <tbody>
          {previousPayments.map((p) => {
            if (p.certifiedAmount !== null) {
              return (
                <tr key={p.id}>
                  <td>{getPaymentLabel(p)}</td>
                  <td>{Units.euro.display(p.certifiedAmount)}</td>
                </tr>
              );
            }
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>
              {`${Units.euro.display(periodCertifiedAmount)}`}
              {periodGrantedAmount > 0 ? (
                <Fragment>
                  <br />
                  <small>{`sur ${Units.euro.display(periodGrantedAmount)} soit ${Units.percentage.display(periodCertifiedAmount / periodGrantedAmount)}`}</small>
                </Fragment>
              ) : null}
            </th>
          </tr>
        </tfoot>
      </table>
    );
  }

  const periodNode = historyTable ? (
    <section className="hashed">
      <h2>{`Période ${payment.period}`}</h2>
      {historyTable}
    </section>
  ) : null;

  const paymentNode = (
    <section className="hashed">
      <h2>Paiement</h2>
      <Print.Properties
        props={[
          ["Tranche", PaymentFragmentLabels[payment.fragment]],
          ["Période", payment.period],
          [
            "Comité technique de suivi",
            payment.committeeDate
              ? dayjs(payment.committeeDate, "YYYY-MM-DD").format("LL")
              : "",
          ],
          [
            "Date de la demande",
            payment.submissionDate
              ? dayjs(payment.submissionDate, "YYYY-MM-DD").format("LL")
              : "",
          ],
        ]}
      />
    </section>
  );

  const receiptsNode = payment.achievements.map((a) => {
    const projectLabel = a.funding.project.label;

    const periods = a.funding.project.periods;

    const beneficiaryIndicators = a.funding.project.indicators.filter(
      (i) => i.origin === IndicatorOrigin.Beneficiary,
    );

    const instrictorIndicators = a.funding.project.indicators.filter(
      (i) => i.origin === IndicatorOrigin.Instructor,
    );

    return (
      <Fragment>
        <section className="hashed">
          <h2>Pour le projet "{projectLabel}"</h2>
          <h3>Justicatifs</h3>
          <table>
            <thead>
              <th>Intitulé</th>
              <th>Commentaire du bénéficiaire</th>
              <th>Commentaire d'instruction</th>
              <th>Décision d'instruction</th>
            </thead>
            <tbody>
              {a.receipts.map((r) => {
                const receipt = Receipts.find(
                  (receipt) => receipt.key === r.key,
                );
                if (!receipt) throw new Error("Missing receipt");

                let decisionLabel: ReactNode;
                if (r.analysis.skippable)
                  decisionLabel = <span className="badge">Non attendu</span>;
                else {
                  if (r.analysis.decision == true)
                    decisionLabel = (
                      <span className="badge success">Conforme</span>
                    );
                  else if (r.analysis.decision == false)
                    decisionLabel = (
                      <span className="badge error">Non conforme</span>
                    );
                  else
                    decisionLabel = <span className="badge">A déterminer</span>;
                }

                return (
                  <tr key={receipt.key}>
                    <td>{receipt.label}</td>
                    <td>{r.notes || ""}</td>
                    <td>{r.analysis.comment || ""}</td>
                    <td>{decisionLabel}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>

        <section className="hashed">
          <h3>Indicateurs d'évaluation</h3>
          <table>
            <Print.IndicatorsTable
              periods={periods}
              indicators={beneficiaryIndicators}
            />
          </table>
          <table>
            <thead>
              <tr>
                <th>Commentaire de l'instructeur</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{a.indicatorNotes || "Aucun commentaire"}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="hashed">
          <h3>Indicateurs d'impact</h3>
          <table>
            <Print.IndicatorsTable
              hideTypes
              periods={periods}
              indicators={instrictorIndicators}
            />
          </table>
        </section>
        <section>
          <h3>Commentaires</h3>
          <table>
            <thead>
              <tr>
                <th>Intervenant</th>
                <th>Commentaire</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bénéficiaire</td>
                <td>{a.requestNotes}</td>
                <td>{Units.euro.display(a.requestedAmount)}</td>
              </tr>
              <tr>
                <td>Instructeur</td>
                <td>{a.instructionNotes}</td>
                <td>{Units.euro.display(a.instructedAmount)}</td>
              </tr>
              <tr>
                <td>Validation niveau 1</td>
                <td>{a.validationNotes}</td>
                <td>{Units.euro.display(a.validatedAmount)}</td>
              </tr>
              <tr>
                <td>Validation niveau 2</td>
                <td>{a.certificationNotes}</td>
                <td>{Units.euro.display(a.certifiedAmount)}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </Fragment>
    );
  });

  const achievements =
    payment.achievements.length > 1 ? payment.achievements : [];

  const amountsNode = (
    <section>
      <h2>Tableau de synthèse des montants</h2>
      <table>
        <thead>
          <tr>
            <th>{getPaymentLabel(payment)}</th>
            {achievements.map((a) => (
              <th key={a.id}>{getProjectLabel(a.funding.project)}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tr>
          <td>{Amounts.granted.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.grantedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.grantedAmount)}</td>
        </tr>
        <tr>
          <td>{Amounts.stock.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.stockAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.stockAmount)}</td>
        </tr>
        <tr className="total">
          <td>{Amounts.available.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.availableAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.availableAmount)}</td>
        </tr>
        <tr className="divider">
          <td></td>
          {achievements.map((a) => (
            <td key={a.id}></td>
          ))}
          <td></td>
        </tr>
        <tr>
          <td>{Amounts.requested.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.requestedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.requestedAmount)}</td>
        </tr>
        <tr>
          <td>{Amounts.instructed.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.instructedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.instructedAmount)}</td>
        </tr>
        <tr>
          <td>{Amounts.validated.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.validatedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.validatedAmount)}</td>
        </tr>
        <tr className="total">
          <td>{Amounts.certified.label}</td>
          {achievements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.certifiedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.certifiedAmount)}</td>
        </tr>
        <tr>
          <td>
            <small>{Amounts.stockable.label}</small>
          </td>
          {achievements.map((a) => (
            <td key={a.id}>
              <small>{Units.euro.display(a.stockableAmount)}</small>
            </td>
          ))}
          <td>
            <small>{Units.euro.display(payment.stockableAmount)}</small>
          </td>
        </tr>
        <tr className="divider">
          <td></td>
          {achievements.map((a) => (
            <td key={a.id}></td>
          ))}
          <td></td>
        </tr>
        {payment.maximalAdvance ? (
          <tr>
            <td>{Amounts.maximalAdvance.label}</td>
            {achievements.map((a) => (
              <td key={a.id}></td>
            ))}
            <td>{Units.euro.display(payment.maximalAdvance || 0)}</td>
          </tr>
        ) : null}
        <tr className="total">
          <td>{Amounts.transfer.label}</td>
          {achievements.map((a) => (
            <td key={a.id}></td>
          ))}
          <td>{Units.euro.display(payment.transferAmount)}</td>
        </tr>
        {payment.remainingAdvance ? (
          <tr>
            <td>
              <small>{Amounts.remainingAdvance.label}</small>
            </td>
            {achievements.map((a) => (
              <td key={a.id}></td>
            ))}
            <td>
              <small>{Units.euro.display(payment.remainingAdvance || 0)}</small>
            </td>
          </tr>
        ) : null}
      </table>
    </section>
  );

  const signaturesNodes = (
    <Fragment>
      <h2>Signatures</h2>
      <Print.SignatureZone
        label="Instruction"
        signature={signatures ? payment.instructionSignature : null}
      />
      <Print.SignatureZone
        label="Validation"
        signature={signatures ? payment.validationSignature : null}
      />
      <Print.SignatureZone
        label="Validation définitive"
        signature={signatures ? payment.certificationSignature : null}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {header}
      <hr />
      {orgsNode}
      {projectsNode}
      <Print.Columns>
        {conventionNode}
        {paymentNode}
      </Print.Columns>
      {periodNode}
      {receiptsNode}
      {amountsNode}
      <Print.PageBreak />
      {signaturesNodes}
    </Fragment>
  );
}
