import React, { Fragment } from "react";
import { sumBy } from "lodash";
import dayjs from "dayjs";
import { ProjectInstructor } from "@hpo/client/models/Project";
import Print from "@hpo/client/utilities/print/Print";
import { getAttachementDescriptor } from "@hpo/client/utilities/project/Attachments";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";
import Units from "@hpo/client/utilities/Units";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";
import Programs from "@hpo/client/utilities/Programs";

type ProjectReportProps = {
  project: ProjectInstructor;
};

export default function ProjectReport(props: ProjectReportProps) {
  const { project } = props;

  const header = (
    <Print.WithLogo logo={"/logo-cg.png"}>
      <p>Rapport d'instruction sur la réponse à l'appel à projet HPO</p>
      <h1>
        {project.organization.businessName}
        {project.label ? (
          <Fragment>
            <br />
            {project.label}
          </Fragment>
        ) : null}
      </h1>
      <p>{Programs[project.type].label}</p>
    </Print.WithLogo>
  );

  const ownerZone = (
    <section className="hashed">
      <h2>Porteur de projet</h2>
      <Print.Properties
        props={[
          ["Raison sociale", project.organization.businessName],
          ["Statut juridique", project.organization.type],
          ["Réprésentant légal", project.organization.legalRepresentativeName],
          [
            "Fonction du réprésentant légal",
            project.organization.legalRepresentativePosition,
          ],
          ["N° SIRET", project.organization.legalId],
          [
            "Adresse",
            <Fragment>
              {project.organization.address.street}
              <br />
              {project.organization.address.postalCode}{" "}
              {project.organization.address.town}
            </Fragment>,
          ],
        ]}
      />
    </section>
  );

  const contactZone = (
    <section className="hashed">
      <h2>Référent du dossier HPO</h2>
      <Print.Properties
        props={[
          ["Prénom et nom", project.contactName],
          ["Téléphone fixe", project.contactPhoneNumber],
          ["Téléphone mobile", project.contactMobilePhoneNumber],
        ]}
      />
    </section>
  );

  const summaryZone = (
    <section className="hashed">
      <h2>Résumé du projet</h2>
      <p>{project.summary}</p>
      <p>Mots clés : {project.keywords.join(" ")}</p>
    </section>
  );

  const compliancesZone = (
    <section className="hashed">
      <h2>Éligbilité</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Conformité</th>
            <th>Commentaires de l'instructeur</th>
          </tr>
        </thead>
        <tbody>
          {project.compliances.map((c) => (
            <tr key={c.id}>
              <td>{c.label}</td>
              <td>
                <Print.AnalysisBadge analysis={c.analysis} />
              </td>
              <td>{c.analysis.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );

  const attachmentsZone = (
    <section className="hashed">
      <h2>Pièces justificatives</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Conformité</th>
            <th>Commentaires de l'instructeur</th>
          </tr>
        </thead>
        <tbody>
          {project.attachments.map((a) => (
            <tr key={a.id}>
              <td>{getAttachementDescriptor(a.key).label}</td>
              <td>
                <Print.AnalysisBadge analysis={a.analysis} />
              </td>
              <td>{a.analysis.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );

  const descriptionZone = (
    <section className="hashed">
      <h2>Description</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Description par le porteur de projet</th>
            <th>Conformité</th>
            <th>Commentaires de l'instructeur</th>
          </tr>
        </thead>
        <tbody>
          {project.descriptions.map((d) => (
            <tr key={d.id}>
              <td>{d.label}</td>
              <td>{d.value}</td>
              <td>
                <Print.AnalysisBadge analysis={d.analysis} />
              </td>
              <td>{d.analysis.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );

  const beneficiaryIndicators = project.indicators.filter(
    (i) => i.origin === IndicatorOrigin.Beneficiary,
  );

  const instructorIndicators = project.indicators.filter(
    (i) => i.origin === IndicatorOrigin.Instructor,
  );
  const indicatorsZone = (
    <Fragment>
      <section className="hashed">
        <h2>Indicateurs d'évaluation</h2>
        <table>
          <Print.IndicatorsTable
            periods={project.periods}
            indicators={beneficiaryIndicators}
            withAnalisys
          />
        </table>
      </section>
      <section className="hashed">
        <h2>Indicateurs d'impact</h2>
        <table>
          <Print.IndicatorsTable
            hideTypes
            periods={project.periods}
            indicators={instructorIndicators}
          />
        </table>
      </section>
    </Fragment>
  );

  const expenses = distpatchExpenses(project.expenses);

  const servicesAnnex = project.annexes.find((a) => a.type === "a2_service");

  const servicesZone = servicesAnnex ? (
    <section className="hashed">
      <h2>Récapitulatif des devis de prestation de services</h2>
      <table>
        <thead>
          <tr>
            <th>Intitulé</th>
            <th>Prestataire</th>
            <th>N° de devis</th>
            <th>Date</th>
            <th>Coût par an</th>
            <th>Coût sur 3 ans</th>
            <th>Aide par an</th>
            <th>Aide sur 3 ans</th>
          </tr>
        </thead>
        <tbody>
          {expenses.service.map((s) => {
            return (
              <tr key={s.id}>
                <td>{s.label}</td>
                <td>{s.provider}</td>
                <td>{s.quotationReference}</td>
                <td>{dayjs(s.quotationDate, "YYYY-MM-DD").format("LL")}</td>
                <td>{Units.euro.display(s.annualCost)}</td>
                <td>{Units.euro.display(s.quotationTotalCost)}</td>
                <td>{Units.euro.display(s.helpAmountPerYear)}</td>
                <td>{Units.euro.display(s.helpAmountThreeYears)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>
              {Units.euro.display(sumBy(expenses.service, (s) => s.annualCost))}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.service, (s) => s.quotationTotalCost),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.service, (s) => s.helpAmountPerYear),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.service, (s) => s.helpAmountThreeYears),
              )}
            </th>
          </tr>
        </tfoot>
      </table>
      <Print.AnalysisDetail analysis={servicesAnnex.analysis} />
    </section>
  ) : null;

  const productsAnnex = project.annexes.find((a) => a.type === "a2_product");

  const productsZone = productsAnnex ? (
    <section className="hashed">
      <h2>Récapitulatif des devis d'achats de produits</h2>
      <table>
        <thead>
          <tr>
            <th>Intitulé</th>
            <th>Prestataire</th>
            <th>N° de devis</th>
            <th>Date</th>
            <th>Unités</th>
            <th>Coût par an</th>
            <th>Coût sur 3 ans</th>
            <th>Aide par an</th>
            <th>Aide sur 3 ans</th>
          </tr>
        </thead>
        <tbody>
          {expenses.product.map((s) => {
            return (
              <tr key={s.id}>
                <td>{s.label}</td>
                <td>{s.provider}</td>
                <td>{s.quotationReference}</td>
                <td>{dayjs(s.quotationDate, "YYYY-MM-DD").format("LL")}</td>
                <td>{Units.other.display(s.quantity)}</td>
                <td>{Units.euro.display(s.annualCost)}</td>
                <td>{Units.euro.display(s.quotationTotalCost)}</td>
                <td>{Units.euro.display(s.helpAmountPerYear)}</td>
                <td>{Units.euro.display(s.helpAmountThreeYears)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>
              {Units.euro.display(sumBy(expenses.product, (s) => s.annualCost))}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.product, (s) => s.quotationTotalCost),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.product, (s) => s.helpAmountPerYear),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(expenses.product, (s) => s.helpAmountThreeYears),
              )}
            </th>
          </tr>
        </tfoot>
      </table>
      <Print.AnalysisDetail analysis={productsAnnex.analysis} />
    </section>
  ) : null;

  const salariesAnnex = project.annexes.find((a) => a.type === "a2_salary");

  const salariesZone = salariesAnnex ? (
    <section className="hashed">
      <h2>Récapitulatif des salaires</h2>
      <table>
        <thead>
          <tr>
            <th>Intitulé</th>
            <th>Nom et prénom</th>
            <th>Fonction du poste affecté à l'action</th>

            <th>Salaire et charges par an</th>
            <th>% ETP affecté</th>
            <th>Salaire et charges affectés par an</th>
            <th>Coût prévisionnel sur 3 ans</th>

            <th>Salaire et charges plafonnées par an</th>
            <th>Montant de l'aide par an</th>
            <th>Montant de l'aide sur 3 ans </th>
          </tr>
        </thead>
        <tbody>
          {expenses.salary.map((s) => {
            return (
              <tr key={s.id}>
                <td>{s.label}</td>
                <td>{s.employeeName}</td>
                <td>{s.employeePosition}</td>

                <td>{Units.euro.display(s.annualCost)}</td>
                <td>{Units.percentage.display(s.affectation)}</td>
                <td>{Units.other.display(s.fullCostOneYear)}</td>
                <td>{Units.euro.display(s.fullCostThreeYears)}</td>

                <td>{Units.euro.display(s.annualCostCeiling)}</td>
                <td>{Units.euro.display(s.eligibleHelpOneYear)}</td>
                <td>{Units.euro.display(s.eligibleHelpThreeYears)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {Units.euro.display(sumBy(expenses.salary, (s) => s.annualCost))}
            </td>
            <td></td>
            <td>
              {Units.other.display(
                sumBy(expenses.salary, (s) => s.fullCostOneYear),
              )}
            </td>
            <td>
              {Units.euro.display(
                sumBy(expenses.salary, (s) => s.fullCostThreeYears),
              )}
            </td>
            <td>
              {Units.euro.display(
                sumBy(expenses.salary, (s) => s.annualCostCeiling),
              )}
            </td>
            <td>
              {Units.euro.display(
                sumBy(expenses.salary, (s) => s.eligibleHelpOneYear),
              )}
            </td>
            <td>
              {Units.euro.display(
                sumBy(expenses.salary, (s) => s.eligibleHelpThreeYears),
              )}
            </td>
          </tr>
        </tfoot>
      </table>
      <Print.AnalysisDetail analysis={salariesAnnex.analysis} />
    </section>
  ) : null;

  const proposalsAnnex = project.annexes.find((a) => a.type === "a3");

  const proposalsZone = proposalsAnnex ? (
    <section className="hashed">
      <h2>Plan de financement</h2>
      <table>
        <thead>
          <tr>
            <th>Actions</th>
            <th>Dépenses</th>
            <th>Coût par an</th>
            <th>Montant de l'aide éligible</th>
            <th>Subvention demandée par le bénéficiaire</th>
            <th>Reste à charge</th>
            <th>Subvention proposée par l'instructeur</th>
            <th>Conformité</th>
            <th>Commentaire de l'instructeur</th>
          </tr>
        </thead>
        <tbody>
          {project.proposals.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.actionLabel}</td>
                <td>{p.expenseLabel}</td>
                <td>{Units.euro.display(p.annualCost)}</td>
                <td>{Units.euro.display(p.elligibleHelp)}</td>
                <td>{Units.euro.display(p.askedSubsidy)}</td>
                <td>{Units.euro.display(p.remainingCharges)}</td>
                <td>{Units.euro.display(p.proposal)}</td>
                <td>
                  <Print.AnalysisBadge analysis={p.analysis} />
                </td>
                <td>{p.analysis.comment}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th>
              {Units.euro.display(
                sumBy(project.proposals, (p) => p.annualCost),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(project.proposals, (p) => p.elligibleHelp),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(project.proposals, (p) => p.askedSubsidy),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(project.proposals, (p) => p.remainingCharges),
              )}
            </th>
            <th>
              {Units.euro.display(
                sumBy(project.proposals, (p) => p.proposal || 0),
              )}
            </th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
      <Print.AnalysisDetail analysis={proposalsAnnex.analysis} />
    </section>
  ) : null;

  const communicationAnnex = project.annexes.find((a) => a.type === "a4");

  const communicationZone = communicationAnnex ? (
    <section className="hashed">
      <h2>Plan de communication</h2>
      <table>
        <thead>
          <tr>
            <th>Actions</th>
            <th>Objectif</th>
            <th>Coût tôtal prévisionnel</th>
            <th>Moyens et outil de la communication</th>
            <th>Planning</th>
          </tr>
        </thead>
        <tbody>
          {project.actions
            .filter((a) => a.type === "communication")
            .map((a) => {
              return (
                <tr key={a.id}>
                  <td>{a.label}</td>
                  <td>{a.goal}</td>
                  <td>{Units.euro.display(a.cost)}</td>
                  <td>{a.means}</td>
                  <td>{a.planning}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Print.AnalysisDetail analysis={communicationAnnex.analysis} />
    </section>
  ) : null;

  const transferAnnex = project.annexes.find((a) => a.type === "a5");

  const transferZone = transferAnnex ? (
    <section className="hashed">
      <h2>Plan de transfert</h2>
      <table>
        <thead>
          <tr>
            <th>Actions</th>
            <th>Objectif</th>
            <th>Coût tôtal prévisionnel</th>
            <th>Moyens et outil de la communication</th>
            <th>Planning</th>
          </tr>
        </thead>
        <tbody>
          {project.actions
            .filter((a) => a.type === "transfer")
            .map((a) => {
              return (
                <tr key={a.id}>
                  <td>{a.label}</td>
                  <td>{a.goal}</td>
                  <td>{Units.euro.display(a.cost)}</td>
                  <td>{a.means}</td>
                  <td>{a.planning}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Print.AnalysisDetail analysis={transferAnnex.analysis} />
    </section>
  ) : null;

  const totalAmount = project.notations.reduce((sum, notation) => {
    return sum + (notation.value || 0);
  }, 0);

  const maximum = project.notations.reduce((sum, notation) => {
    return sum + notation.maximum;
  }, 0);

  const notationZone = (
    <section className="hashed">
      <h2>
        Notation des critères de selection du projet par le service instructeur
      </h2>
      <table>
        <thead>
          <tr>
            <th>Critères d’appréciation des projets</th>
            <th>Notation</th>
          </tr>
        </thead>
        <tbody>
          {project.notations.map((n) => {
            return (
              <tr key={n.id}>
                <td>
                  {n.criteria}
                  <p>
                    <small>{n.description}</small>
                  </p>
                </td>
                <td>
                  {n.value} / {n.maximum}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>
              {totalAmount} / {maximum}
            </th>
          </tr>
        </tfoot>
      </table>
    </section>
  );

  const conclusionsZone = project.investigation ? (
    <section className="hashed">
      <h2>Conclusion de l'instruction</h2>
      <Print.Properties
        props={[
          ["Commentaires", project.investigation.comment],
          [
            "Avis",
            project.investigation.decision === "favourable" ? (
              <Print.Badge type="success">Favorable</Print.Badge>
            ) : project.investigation.decision ===
              "favourable-with-reservations" ? (
              <Print.Badge type="success">Favorable avec réserves</Print.Badge>
            ) : (
              <Print.Badge type="error">Défavorable</Print.Badge>
            ),
          ],
          ["Montant par an", Units.euro.display(project.investigation.amount)],
        ]}
      />
    </section>
  ) : null;

  return (
    <Fragment>
      {header}
      <hr />
      <section>
        <Print.Columns>
          {ownerZone}
          <div>
            {contactZone}
            {summaryZone}
          </div>
        </Print.Columns>
      </section>
      {compliancesZone}
      {attachmentsZone}
      {descriptionZone}
      {indicatorsZone}
      {servicesZone}
      {productsZone}
      {salariesZone}
      {proposalsZone}
      {communicationZone}
      {transferZone}
      {notationZone}
      <Print.PageBreak />
      {conclusionsZone}
      <Print.SignatureZone
        signature={project.instructorSignature}
        label="Signature de l'instructeur"
      />
    </Fragment>
  );
}
