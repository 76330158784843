import { useState } from "react";
import dayjs from "dayjs";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import WithHelp from "../RootNavigator/assistance/Help/WithHelp";
import YearlyReport, { YearlyReportProps } from "./YearlyReport";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import useData from "@hpo/client/utilities/useData";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Spacer from "@hpo/client/utilities/Spacer";
import Buttons from "@hpo/client/components/Buttons";
import Button from "@hpo/client/components/Button";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import PrintPreviewModal from "@hpo/client/utilities/print/PrintPreviewModal";

import MultiSelectField from "@hpo/client/utilities/fields/MultiSelectField";
import TextField from "@hpo/client/utilities/fields/TextField";
import T from "@hpo/client/components/text/Text";
import Await from "@hpo/client/utilities/Await";
import List, { ListItem } from "@hpo/client/utilities/List";
import smoothJoin from "@hpo/client/utilities/smoothJoin";

export default function YearlyReportNavigator() {
  const server = useServerSdk();
  const [title, setTitle] = useState<string | null>("Rapport du programme HPO");
  const [periods, setPeriods] = useState<Array<string>>([]);
  const [options] = useData(() => server.getPeriods(), []);

  const [yearlies, yearliesData] = useData(() => server.getYearlies(), []);

  const [reportProps, setReportProps] = useState<Omit<
    YearlyReportExportProps,
    "onClose"
  > | null>(null);

  const [onPreview] = useSubmitCallback(async () => {
    if (title === null) throw new MessageException("Titre manquant", null);
    if (periods.length === 0)
      throw new MessageException("Sélectionnez au moins 1 période", null);
    const overall = await server.getOverallStatistics(periods);
    const tags = await server.getTagsPerformance(periods);
    const projects = await server.getProjectsPerformance(periods);
    setReportProps({
      title,
      periods,
      overall,
      tags,
      projects,
    });
  }, [periods]);

  const [onSubmit] = useSubmitCallback(async () => {
    if (title === null) throw new MessageException("Titre manquant", null);
    if (periods.length === 0)
      throw new MessageException("Sélectionnez au moins 1 période", null);
    await server.buildYealyReport(title, periods);
    yearliesData.reload();
  }, [periods]);

  return (
    <Page>
      <PageHeader
        title="Rapport périodique"
        subtitle="Générez un rapport PDF reprenant les statistiques générales, les indicateurs d'impact et les indicateurs d'évaluation pour les périodes de votre choix"
      />
      <Spacer />
      {reportProps ? (
        <YearlyReportPreview
          {...reportProps}
          onClose={() => setReportProps(null)}
        />
      ) : (
        <FieldsForm onSubmit={onSubmit}>
          <WithHelp
            display="mouseover"
            title="Statistiques générales"
            text="Affiche le nombre de structures et de projets financés, les montants conventionnés et versés, et le taux de consommation pour chaque appel à projet. Vous pouvez sélectionner les périodes pour lesquelles vous souhaitez obtenir ces statistiques"
          >
            <MultiSelectField
              label="Périodes à prendre en compte dans le rapport"
              options={options}
              renderOption={(p) => ({ label: p })}
              keyExtractor={(p) => p}
              value={periods}
              onChange={setPeriods}
            />
          </WithHelp>
          <Spacer />
          <TextField
            label="Titre du rapport"
            value={title}
            onChange={setTitle}
          />
          <Spacer />
          <Buttons>
            <Button
              onClick={onPreview}
              style="discreet"
              label="Prévisualiser le rapport"
            />
            <Button submit label="Rédiger le rapport" />
          </Buttons>
        </FieldsForm>
      )}
      <Spacer />
      <T style="subtitle">Précédents rapports</T>
      <Await promise={yearlies}>
        {(yearlies) => (
          <List
            data={yearlies}
            renderItem={(y) => (
              <ListItem
                label={y.title}
                help={[
                  `Rédigé le ${dayjs(y.createdAt).format("LL")} par ${y.author.name} sur ${smoothJoin(y.periods)}`,
                ]}
                right={<Button href={y.report} icon="eye" style="discreet" />}
              />
            )}
          />
        )}
      </Await>
    </Page>
  );
}

type YearlyReportExportProps = YearlyReportProps & {
  onClose: () => void;
};

function YearlyReportPreview(props: YearlyReportExportProps) {
  const { onClose, ...otherProps } = props;
  return (
    <PrintPreviewModal
      width="29.7cm"
      margin="1cm"
      visible={true}
      onClose={onClose}
    >
      <YearlyReport {...otherProps} />
    </PrintPreviewModal>
  );
}
